
import { defineComponent, toRefs, reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { LeftOutlined } from "@ant-design/icons-vue";
import useDelay from "@/hooks/claimDelay/useDelay";
import fixTable from "@/hooks/checking/useFixTable";
import { RequestTypeListEnum } from "@/views/Delay/type";
import DealerInfoDrawer from "@/views/Delay/components/DealerInfoDrawer.vue";
import RegionClaimAPI from "@/API/claimDelay/regionClaim";
import { StatusListMap, StatusListMap2 } from "@/views/Delay/type";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    LeftOutlined,
    DealerInfoDrawer,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      requestCode,
      requestType,
      programCode,
      programName,
      programType,
      dealerCode,
      dealerName,
      pagination,
      state,
      downLoad,
    } = useDelay();

    const id = ref<string>("");

    const requestDate = ref<string>("");
    const requestTypeTitle = ref<string>("");
    const programTypeTitle = ref<string>("");
    const regionApprovedDate = ref<string>("");

    const requestStatus = ref<string>("1");

    const documentName = ref<string>("");

    const tableData = ref([]);
    const columns = [
      {
        title: "VIN",
        dataIndex: "vin",
      },
      {
        title: "Retail Date",
        dataIndex: "retailDate",
      },
      {
        title: "Model Name",
        dataIndex: "modelName",
      },
      {
        title: "Model Year",
        dataIndex: "modelYear",
      },
      {
        title: "Status",
        dataIndex: "frontStatus",
        slots: { customRender: "frontStatus" },
      },
      {
        title: "Operation",
        slots: { customRender: "operation" },
      },
    ];
    //* 限定表格
    const height: number =
      50 + 8 + 80 + 8 + 133 + 10 + 8 + 10 + 32 + 10 + 5 + 135; //+ 48 分页;
    const tableHeight = fixTable(height);

    const getTableData = () => {
      state.selectedRowKeys = [];
      state.selectedRows = [];
      RegionClaimAPI.getClaimDetail(id.value).then((res) => {
        tableData.value = res.details;
        regionApprovedDate.value = res.regionApproveDate;
        requestStatus.value = res.requestStatus;
      });
    };

    //  映射Status列
    const showStatus = (record: any): string => {
      if (
        record.frontStatus === StatusListMap["Submitted"] ||
        record.frontStatus === "Approve"
      ) {
        record.frontStatus = "Approve";
        return "Approve";
      }
      if (record.frontStatus === "Reject") {
        record.frontStatus = "Reject";
        return "Reject";
      }
      return StatusListMap2[record.frontStatus];
    };

    const pageChange = (page: number) => {
      pagination.currentPage = page;
      getTableData();
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageSize = pageSize;
      pagination.currentPage = page;
      getTableData();
    };

    const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
      state.selectedRowKeys = selectedRowKeys;
      state.selectedRows = selectedRows;
    };
    const getCheckboxProps = (record: any) => ({
      // Column configuration not to be checked
      disabled:
        record.status === StatusListMap["Region Approved"] ||
        record.status === StatusListMap["Region Reject"] ||
        record.status === StatusListMap["SIPO Approved"] ||
        record.status === StatusListMap["SIPO Reject"],
      name: record.status,
    });

    const goBack = () => {
      router.go(-1);
    };

    const getDocument = () => {
      const params = {
        applyInfoId: id.value,
      };
      RegionClaimAPI.getDocument({ params }).then((res) => {
        downLoad(res);
      });
    };

    const dealerInfoVisible = ref<boolean>(false);
    const showMoreInfo = () => {
      dealerInfoVisible.value = true;
    };
    const closeMoreInfo = () => {
      dealerInfoVisible.value = false;
    };

    const judgeSelectedRowsLength = () => {
      const length = state.selectedRowKeys.length;
      if (!length) {
        message.error("Please select at least one VIN.");
        return true;
      }
      return false;
    };
    const approveSelected = () => {
      if (judgeSelectedRowsLength()) {
        return;
      } else {
        state.selectedRows.forEach((row: any) => {
          row.frontStatus = "Approve";
        });
      }
    };
    const rejectSelected = () => {
      if (judgeSelectedRowsLength()) {
        return;
      } else {
        state.selectedRows.forEach((row: any) => {
          row.frontStatus = "Reject";
        });
      }
    };

    const approveRow = (record: any) => {
      record.frontStatus = "Approve";
    };
    const rejectRow = (record: any) => {
      record.frontStatus = "Reject";
    };

    //?
    const approveIdListGlobal = ref<string[]>([]);
    const rejectIdListGlobal = ref<string[]>([]);
    const approveModalVisible = ref<boolean>(false);
    const approveConfirm = () => {
      approveModalVisible.value = false;
      const params = {
        confirm: true,
        approveIdList: approveIdListGlobal.value,
        rejectIdList: rejectIdListGlobal.value,
        requestId: id.value,
      };
      RegionClaimAPI.submitClaim(params).then((res) => {
        getTableData();
      });
    };
    const approveCancel = () => {
      approveModalVisible.value = false;
    };
    const submit = () => {
      if (requestStatus.value !== StatusListMap["Submitted"]) {
        message.error("The application in this status can’t be operated.");
        return;
      }
      if (judgeSelectedRowsLength()) {
        return;
      }
      const approveIdList: string[] = [];
      const rejectIdList: string[] = [];
      state.selectedRows.forEach((item: any) => {
        if (item.frontStatus === "Approve") {
          approveIdList.push(item.id);
        }
        if (item.frontStatus === "Reject") {
          rejectIdList.push(item.id);
        }
      });
      const params = {
        confirm: false,
        approveIdList,
        rejectIdList,
        requestId: id.value,
      };
      RegionClaimAPI.submitClaim(params).then((res) => {
        if (res && res.code && res.code === "50235") {
          approveModalVisible.value = true;
          approveIdListGlobal.value = approveIdList;
          rejectIdListGlobal.value = rejectIdList;
        } else {
          getTableData();
        }
      });
    };
    const disableBtn = (record: any) => {
      const status = [StatusListMap["Submitted"]];
      if (!status.includes(record.status)) {
        return "disable-btn";
      }
    };

    onMounted(() => {
      id.value = route.params.id as string;
      requestCode.value = route.params.requestCode as string;
      requestType.value = route.params.requestType as string;
      requestDate.value = route.params.requestDate as string;
      programCode.value = route.params.programCode as string;
      programName.value = route.params.programName as string;
      programType.value = route.params.programType as string;
      dealerCode.value = route.params.dealerCode as string;
      dealerName.value = route.params.dealerName as string;
      requestTypeTitle.value = RequestTypeListEnum[requestType.value];
      programTypeTitle.value = programType.value;
      documentName.value = route.params.documentName as string;
      getTableData();
    });
    return {
      documentName,
      goBack,
      tableHeight,
      onSelectChange,
      ...toRefs(pagination),
      ...toRefs(state),
      tableData,
      columns,
      // ...toRefs(claimRequestDetailValue),
      requestCode,
      requestTypeTitle,
      requestDate,
      programCode,
      programName,
      programTypeTitle,
      dealerCode,
      dealerName,
      regionApprovedDate,
      pageChange,
      sizeChange,
      getDocument,
      dealerInfoVisible,
      showMoreInfo,
      closeMoreInfo,
      approveRow,
      rejectRow,
      submit,
      showStatus,
      disableBtn,
      getCheckboxProps,
      approveSelected,
      rejectSelected,
      approveModalVisible,
      approveConfirm,
      approveCancel,
    };
  },
});
